import React from "react"
import { Link } from "gatsby"
import mwbe from "../../content/assets/mwbe.png"

console.log(mwbe)

const Layout = props => {
  const { title, children } = props
  const [toggleNav, setToggleNav] = React.useState(false)
  return (
    <div className={`site-wrapper ${toggleNav ? `site-head-open` : ``}`}>
      <header className="site-head">
        <div className="site-head-container">
          <a
            className="nav-burger"
            href={`#`}
            onClick={() => setToggleNav(!toggleNav)}
          >
            <div
              className="hamburger hamburger--collapse"
              aria-label="Menu"
              role="button"
              aria-controls="navigation"
            >
              <div className="hamburger-box">
                <div className="hamburger-inner" />
              </div>
            </div>
          </a>
          <nav id="swup" class="site-head-left">
            <ul className="nav" role="menu">
              <li className="nav-home nav-current" role="menuitem">
                <Link to={`/`}>Home</Link>
              </li>
              <li className="nav-services" role="menuitem">
                <Link to={`/services`}>Services</Link>
              </li>
              <li className="nav-contact" role="menuitem">
                <Link to={`/contact`}>Contact</Link>
              </li>
            </ul>
          </nav>
          <div className="site-head-center">
            <Link className="site-head-logo" to={`/`}>
              {title}
            </Link>
          </div>
          <div className="site-head-right">
            <div className="social-links">
              <a
                href="https://www.instagram.com/alexcovo"
                title="Instagram"
                target="_blank"
                rel="noopener noreferrer"
              >
                Instagram
              </a>
              {/*          <a
                href="https://www.facebook.com/alexcovostudionyc"
                title="Facebook"
                target="_blank"
                rel="noopener noreferrer"
              >
                Facebook
              </a>
          */}
              {/*     <a
                href="https://www.twitter.com/alexcovo"
                title="Twitter"
                target="_blank"
                rel="noopener noreferrer"
              >
                Twitter
              </a>

         */}

              {/* 
              <Link
                to={`https://www.instagram.com/alexcovo`}
                title="Instagram"
                target="_blank"
                rel="noopener noreferrer"
              >
                Instagram
              </Link>
*/}
            </div>
          </div>
        </div>
      </header>
      <main id="site-main" className="site-main">
        <div id="swup2" className="transition-fade">
          {children}
        </div>
      </main>
      <footer className="site-foot">
        &copy; {new Date().getFullYear()} <Link to={`/`}>{title}</Link> &nbsp;
        &mdash; &nbsp;
        {/*        <Link
                to={`https://www1.nyc.gov/site/sbs/businesses/subcontractors-resource-guide.page`}
                title="M/WBE"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={mwbe} alt="M/WBE"></img>
              </Link> 
*/}
        <img src={mwbe} alt="M/WBE"></img>
        <p></p>
        This Web3 powered site was built on the decentralized web using{" "}
        <div className="post-content-body">
          <a href="https://ipfs.io/" target="_blank" rel="noopener noreferrer">
            IPFS,
          </a>
          <a
            href="https://gatsbyjs.org"
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
            Gatsbyjs,
          </a>
          <a
            href="https://ens.domains"
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
            Ethereum Name Service,
          </a>
          <a
            href="https://https://fleek.co/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
            Fleek,
          </a>
          <a
            href="https://slate.host"
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
            Slate,
          </a>
          <a
            href="https://metamask.io"
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
            Metamask
          </a>
        </div>
      </footer>
    </div>
  )
}

export default Layout
